import React from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import RadioToggle from 'shared/app/components/radio-toggle';

import { pathnameSelector } from 'shared/app/state/selectors/routes';
import {
  inMenuStoreLocatorSelector,
  appQueryStringSelector,
} from '../../state/selectors';

import messages from '../../messages';
import { commonMessages } from 'shared/app/messages/common-words';

const DeliveryToggle = () => {
  const dispatch = useDispatch();
  const pathname = useSelector(pathnameSelector);
  const inMenuStoreLocator = useSelector(inMenuStoreLocatorSelector);
  const appQueryString = useSelector(appQueryStringSelector);
  const { formatMessage } = useIntl();

  const orderOptions = [
    {
      displayName: formatMessage(commonMessages.pickupLabel),
      value: 'pickup',
      checked: !pathname.includes('/delivery'),
    },
    {
      displayName: formatMessage(commonMessages.deliveryLabel),
      value: 'delivery',
      checked: pathname.includes('/delivery'),
    },
  ];

  const onChange = (e) => {
    const rootPath = inMenuStoreLocator
      ? '/menu/store-locator'
      : '/store-locator';
    if (e.target.value === 'delivery') {
      dispatch(push(`${rootPath}/delivery`));
    }
    if (e.target.value === 'pickup') {
      const storeLocatorPath = inMenuStoreLocator
        ? rootPath
        : `${rootPath}${appQueryString ?? ''}`;
      dispatch(push(storeLocatorPath));
    }
  };

  return (
    <RadioToggle
      onChange={onChange}
      radioLegend={formatMessage(messages.deliveryToggleLegend)}
      radioName="orderType"
      radioOptions={orderOptions}
    />
  );
};

export default DeliveryToggle;
