import { lazyFullPage } from 'shared/app/shell';

import rootReducer from './state/reducers/root-reducer';
import { updateStoreLocatorUrl } from './state/actions/application';
import { fetchLocations } from './state/actions/location';
import {
  shouldFetchLocationsSelector,
  shouldUpdateUrlSelector,
} from './state/selectors';
import {
  FETCH_LOCATIONS,
  REQUEST_NEAREST_LOCATION,
} from './state/actions/types';

export default {
  name: 'storeLocator',
  reducer: rootReducer,
  routes: {
    '/store-locator/*': {
      fullPageComponent: lazyFullPage(
        () =>
          import(
            /* webpackChunkName: 'store-locator-page' */ './components/root/store-locator'
          )
      ),
      usesFullPageFlexLayout: true,
      hideGlobalFooter: true,
    },
  },
  effects: [
    {
      selector: shouldUpdateUrlSelector,
      actionCreator: updateStoreLocatorUrl,
    },
    { selector: shouldFetchLocationsSelector, actionCreator: fetchLocations },
  ],
  asyncActions: [FETCH_LOCATIONS, REQUEST_NEAREST_LOCATION],
};
