import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import LocationCardContainer from '../location-card-container';
import LocationCardDetails from '../location-card-details';

import {
  highlightLocation,
  unhighlightLocation,
  selectLocation,
} from '../../state/actions/location';

import clickModifiers from '../../utils/click-modifiers';

import styles from './styles.cssm';
import messages from './messages';

// eslint-disable-next-line max-statements
const LocationCard = forwardRef(
  ({ location, isActive = false, orderAndPickupFeatures }, ref) => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();

    const { store } = location;

    const handleClick = (e) => {
      if (clickModifiers(e)) {
        return;
      }
      e.preventDefault();
      dispatch(
        selectLocation(store.storeNumber, {
          location,
          userOverride: false,
        })
      );
    };

    const handleOnMouseEnter = () => {
      if (isActive) {
        return;
      }
      dispatch(highlightLocation(store.storeNumber));
    };

    const handleOnMouseLeave = () => {
      if (isActive) {
        return;
      }
      dispatch(unhighlightLocation());
    };

    return (
      <LocationCardContainer
        className={classNames(
          'linkOverlay sb-global-gutters py3 relative',
          isActive && `${styles.isActive} isActive`
        )}
        data-e2e={isActive ? 'activeCard' : null}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        ref={ref}
      >
        <button
          aria-label={formatMessage(
            isActive
              ? messages.storeCardActiveButtonLabel
              : messages.storeCardInactiveButtonLabel,
            {
              storeName: store.name,
            }
          )}
          className={`linkOverlay__primary ${styles.overlayLink}`}
          data-e2e="cardButton"
          disabled={isActive}
          onClick={handleClick}
        />
        <LocationCardDetails
          location={location}
          orderAndPickupFeatures={orderAndPickupFeatures}
        />
      </LocationCardContainer>
    );
  }
);

export default LocationCard;
