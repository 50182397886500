import React from 'react';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';
const EXPANDED_STORE_OVERLAY_ID = 'ExpandedStoreOverlay';

const Header = ({ storeData }) => {
  const { name, openStatusFormatted } = storeData;

  return (
    <React.Fragment>
      <Heading
        className="text-bold mb1"
        id={EXPANDED_STORE_OVERLAY_ID}
        size="lg"
        tagName="h2"
      >
        {name}
      </Heading>
      <p className="color-textBlackSoft text-sm pb3">{openStatusFormatted}</p>
    </React.Fragment>
  );
};

export default Header;
