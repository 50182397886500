import { pickBy } from 'lodash';
import { push } from 'redux-first-history';

import { guestPaymentInstrumentTokenIsExpiredSelector } from './selectors/payments';

import {
  CLEAR_GUEST_PAYMENT_TOKEN,
  GUEST_PAYMENT_TOKEN_IS_EXPIRED,
  BUY_DIGITAL_GIFT,
  BUY_DIGITAL_GIFT_ERROR,
  BUY_DIGITAL_GIFT_SUCCESS,
  UPDATE_GIFT_PURCHASE_STATUS,
  FETCH_DIGITAL_GIFTS_CATALOG,
  FETCH_DIGITAL_GIFTS_CATALOG_ERROR,
  FETCH_DIGITAL_GIFTS_CATALOG_SUCCESS,
  FETCH_GIFT_DETAILS,
  FETCH_GIFT_DETAILS_ERROR,
  FETCH_GIFT_DETAILS_SUCCESS,
  SET_SELECTED_GIFT_PAYMENT_INSTRUMENT,
  UPDATE_GIFT_FORM_DATA,
  UPDATE_NUMBER_OF_RECIPIENTS,
} from '../actions';
import {
  GET_DIGITAL_GIFTS,
  GET_DIGITAL_GIFT_CARD,
} from '../../universal/gql-operation-ids';

import {
  DUPLICATE_RECIPIENT_EMAIL_ERROR,
  getNotificationForErrorCode,
} from './errors';
import { showCodedErrorNotification } from 'shared/app/utils/show-coded-error-notification';
import { currentRouteSelector } from 'shared/app/state/selectors/routes';
import {
  localeTagSelector,
  currencyCodeSelector,
} from 'shared/app/state/selectors/locales';
import runSequentially from 'shared/app/utils/run-sequentially';
import {
  GIFT_INPUTS_KEY,
  removeSessionStorageItem,
} from 'shared/app/utils/session-storage';
import { egiftsSelector, giftFormDataSelector } from './selectors';
import { trackGiftCardOrderSuccess } from './track-event';

import PaymentInstrumentExpiredDialog, {
  PAYMENT_INSTRUMENT_EXPIRED_DIALOG_ID,
} from '../components/payment-instrument-expired-dialog';

import {
  SUCCESS_PURCHASE_STATUS,
  PENDING_PURCHASE_STATUS,
  ERROR_PURCHASE_STATUS,
} from 'shared/app/components/gift-receipt/constants';

const EPROMISERETRY = 'EPROMISERETRY';
const DEFAULT_STATUS_CALL_DELAY = 4000;

// Fetch Digital Gifts
export const fetchDigitalGiftsCatalog =
  () =>
  (dispatch, getState, { gqlFetch }) => {
    dispatch({ type: FETCH_DIGITAL_GIFTS_CATALOG, payload: null });

    return gqlFetch({
      operationId: GET_DIGITAL_GIFTS,
      variables: {
        locale: localeTagSelector(getState()),
      },
    })
      .then((payload) => {
        if (!payload || !payload.gift) {
          if (payload.errors) {
            throw new Error(payload.errors[0].message);
          }

          throw new Error('Failed to fetch digital gifts catalog');
        }

        dispatch({
          type: FETCH_DIGITAL_GIFTS_CATALOG_SUCCESS,
          payload: payload.gift,
        });
      })
      .catch((error) => {
        const { code: errorCode } = error;
        dispatch({ type: FETCH_DIGITAL_GIFTS_CATALOG_ERROR, error });
        dispatch(
          showCodedErrorNotification(errorCode, getNotificationForErrorCode)
        );
      });
  };

export const fetchGiftDetails =
  () =>
  (dispatch, getState, { gqlFetch }) => {
    const productNumber = currentRouteSelector(getState()).params.productNumber;

    if (productNumber.length !== 8) {
      dispatch({
        type: FETCH_GIFT_DETAILS_ERROR,
        payload: productNumber,
      });
      dispatch(
        showCodedErrorNotification(
          'giftFetchError',
          getNotificationForErrorCode
        )
      );
      return;
    }
    dispatch({ type: FETCH_GIFT_DETAILS, payload: productNumber });

    return gqlFetch({
      operationId: GET_DIGITAL_GIFT_CARD,
      variables: {
        productNumber,
        locale: localeTagSelector(getState()),
      },
    })
      .then((payload) => {
        if (!payload?.giftCard) {
          if (payload.errors) {
            throw new Error(payload.errors[0].message);
          }

          throw new Error('Failed to fetch digital gift card');
        }

        dispatch({
          type: FETCH_GIFT_DETAILS_SUCCESS,
          payload: payload.giftCard,
        });
      })
      .catch(() => {
        dispatch({
          type: FETCH_GIFT_DETAILS_ERROR,
          payload: productNumber,
        });
        dispatch(
          showCodedErrorNotification(
            'giftFetchError',
            getNotificationForErrorCode
          )
        );
      });
  };

// Gift Purchase
export const updateGiftFormData = (data) => ({
  type: UPDATE_GIFT_FORM_DATA,
  payload: data,
});

export const updateNumberOfRecipients = (data) => ({
  type: UPDATE_NUMBER_OF_RECIPIENTS,
  payload: data,
});

const handlePurchaseConfirmation = (dispatch, closeModal, { vars }) => {
  const { actionType, ...otherData } = vars;

  runSequentially(
    () =>
      dispatch({
        type: actionType,
        payload: {
          ...otherData,
        },
      }),
    () => closeModal(),
    () => dispatch(push('/gift/confirmation')),
    () =>
      dispatch({
        type: CLEAR_GUEST_PAYMENT_TOKEN,
      })
  );
  removeSessionStorageItem(GIFT_INPUTS_KEY);

  trackGiftCardOrderSuccess({
    // eslint-disable-next-line camelcase
    gift_card_id: vars.product,
    // eslint-disable-next-line camelcase
    gift_card_recipient_count: vars.numberOfRecipients,
  });
};

export const checkGuestPaymentInstrumentExpiration =
  ({ guestPaymentSelected, openModal, closeModal, paymentInstrumentToken }) =>
  (dispatch, getState) => {
    const state = getState();
    // if it's guest checkout and the token is null treat the token as
    // expired and force the user back through the add payment flow
    const paymentInstrumentIsExpired =
      guestPaymentInstrumentTokenIsExpiredSelector(state) ||
      (guestPaymentSelected && !paymentInstrumentToken);

    if (paymentInstrumentIsExpired) {
      dispatch({ type: GUEST_PAYMENT_TOKEN_IS_EXPIRED });
      closeModal();

      openModal({
        component: PaymentInstrumentExpiredDialog,
        ariaLabelledBy: PAYMENT_INSTRUMENT_EXPIRED_DIALOG_ID,
      });
    }

    return paymentInstrumentIsExpired;
  };

export const getGiftPurchaseStatus =
  ({ requestId, queryParams = '', confirmationData, closeModal }) =>
  (dispatch, getState, { apiFetch }) => {
    const isComingFromPendingCheckAgain = queryParams === '?zero-retries=true';

    const updateGiftPurchaseStatus = ({ vars }) => {
      return dispatch({
        type: UPDATE_GIFT_PURCHASE_STATUS,
        payload: vars,
      });
    };

    return apiFetch(`/apiproxy/v1/gift/gift-purchase-status${queryParams}`, {
      method: 'GET',
      headers: {
        'X-Request-ID': requestId,
      },
      includeResponse: true,
    })
      .then((statusResponse) => {
        const processingStatusData = statusResponse?.data?.context;
        const orderNumber =
          statusResponse?.orderNumber ?? // ACTIVE
          processingStatusData?.orderNumber ?? // PROCESSING
          null;

        if (processingStatusData?.code === EPROMISERETRY) {
          if (isComingFromPendingCheckAgain) {
            return updateGiftPurchaseStatus({
              vars: {
                purchaseStatus: PENDING_PURCHASE_STATUS,
                orderNumber,
              },
            });
          }
          return handlePurchaseConfirmation(dispatch, closeModal, {
            vars: {
              ...confirmationData,
              purchaseStatus: PENDING_PURCHASE_STATUS,
              orderNumber,
              actionType: BUY_DIGITAL_GIFT_SUCCESS,
            },
          });
        }
        if (isComingFromPendingCheckAgain) {
          return updateGiftPurchaseStatus({
            vars: {
              purchaseStatus: SUCCESS_PURCHASE_STATUS,
              orderNumber,
            },
          });
        }
        return handlePurchaseConfirmation(dispatch, closeModal, {
          vars: {
            ...confirmationData,
            purchaseStatus: SUCCESS_PURCHASE_STATUS,
            orderNumber,
            actionType: BUY_DIGITAL_GIFT_SUCCESS,
          },
        });
      })
      .catch((errData) => {
        // gift-purchase success, gift-purchase-status error
        const errorDetails = errData?.data?.error?.data?.context;
        if (isComingFromPendingCheckAgain) {
          return updateGiftPurchaseStatus({
            vars: {
              purchaseStatus: ERROR_PURCHASE_STATUS,
              ...errorDetails,
            },
          });
        }
        return handlePurchaseConfirmation(dispatch, closeModal, {
          vars: {
            ...confirmationData,
            ...errorDetails,
            purchaseStatus: ERROR_PURCHASE_STATUS,
            actionType: BUY_DIGITAL_GIFT_ERROR,
          },
        });
      });
  };

/* eslint-disable max-statements */
export const purchaseGift =
  ({
    guestPaymentSelected,
    openModal,
    closeModal,
    paymentInstrumentId = null,
    paymentInstrumentToken = null,
    paymentType,
    requestId,
    altText,
    largeImageUrl,
  }) =>
  (dispatch, getState, { apiFetch }) => {
    const state = getState();
    const giftFormData = giftFormDataSelector(state);
    const egifts = egiftsSelector(state);
    const { senderName, senderEmail } = giftFormData;
    const currency = currencyCodeSelector(state) || 'USD';

    const body = pickBy({
      requestId,
      orderSource: 'SBUX',
      locale: localeTagSelector(state),
      currency,
      paymentType,
      paymentInstrumentId,
      paymentInstrumentToken,
      senderEmail,
      senderName,
      egifts,
    });

    const isExpired = dispatch(
      checkGuestPaymentInstrumentExpiration({
        guestPaymentSelected,
        openModal,
        closeModal,
        paymentInstrumentToken,
      })
    );
    if (isExpired) {
      return;
    }

    dispatch({ type: BUY_DIGITAL_GIFT });

    const { amount, product, message, deliveryMethod } = egifts[0];
    const recipients = egifts.map(({ recipientEmail, recipientName }) => ({
      recipientEmail,
      recipientName,
    }));

    const confirmationData = {
      amount,
      deliveryMethod,
      message,
      numberOfRecipients: egifts.length,
      product,
      totalAmount: amount * egifts.length,
      requestId,
      recipients,
      altText,
      largeImageUrl,
    };

    return apiFetch('/apiproxy/v1/gift/gift-purchase', {
      method: 'post',
      body,
      includeRisk: true,
    })
      .then((response) => {
        const delay = parseInt(response.delay) || DEFAULT_STATUS_CALL_DELAY;

        const queryParams = `?individualGiftAmount=${amount}&egiftProductNumber=${product}&numberOfGifts=${egifts.length}&paymentType=${paymentType}`;

        setTimeout(() => {
          return getGiftPurchaseStatus({
            requestId: response?.requestId,
            queryParams,
            confirmationData,
            closeModal,
          })(dispatch, getState, { apiFetch });
        }, delay);
      })
      .catch((error) => {
        // gift-purchase error
        const code = error?.data?.error?.code;
        error.code = code;

        runSequentially(
          () =>
            dispatch({
              error: { code },
              type: BUY_DIGITAL_GIFT_ERROR,
              payload: requestId,
            }),
          () => {
            dispatch(
              showCodedErrorNotification(code, getNotificationForErrorCode)
            );
            if (code === DUPLICATE_RECIPIENT_EMAIL_ERROR) {
              closeModal();
            }
          },
          () =>
            dispatch({
              type: CLEAR_GUEST_PAYMENT_TOKEN,
            })
        );
        throw error;
      });
  };

export const setSelectedPaymentInstrumentId = (paymentInstrumentId) => ({
  type: SET_SELECTED_GIFT_PAYMENT_INSTRUMENT,
  payload: paymentInstrumentId,
});
