import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';

import FrapContainer from 'shared/app/components/frap-container';
import { currentRouteSelector } from 'shared/app/state/selectors/routes';
import { signedInSelector } from 'shared/app/bundles/user';

import AccessibleDescription from './accessible-description';
import ConfirmStoreFrap from './../confirm-store-frap';
import Header from './header';
import LocationInfo from './location-info';
import StorePickUpOptions from './store-pickup-options';
import StoreAmenities from './store-amenities';
import StoreSchedule from './store-schedule';
import {
  inStandaloneStoreLocatorSelector,
  inMenuStoreLocatorSelector,
  expandedStoreStateSelector,
} from '../../state/selectors';
import { confirmStore } from '../../state/actions/confirm-store';
import StoreWarnings from '../location-card-details/store-warnings.js';
import OrderingUnavailableDialog, {
  ORDERING_UNAVAILABLE_DIALOG_ID,
} from 'shared/app/components/ordering-unavailable-dialog/index.js';

const ExpandedLocationContent = () => {
  const inStandaloneStoreLocator = useSelector(
    inStandaloneStoreLocatorSelector
  );
  const inMenuStoreLocator = useSelector(inMenuStoreLocatorSelector);
  const router = useSelector(currentRouteSelector);
  const location = useSelector(expandedStoreStateSelector);
  const isSignedIn = useSelector(signedInSelector);

  const { store } = location ?? {};

  const { openModal, closeModal } = useModalContext();
  const dispatch = useDispatch();

  const labelId = 'expandedLocationCardLabel';
  const paddingClasses = inMenuStoreLocator ? 'frapPadding' : 'pb4 lg-pb6';

  const confirmStoreOnClickHandler = () => {
    const {
      open: isStoreOpen,
      mobileOrdering: { availability, guestOrdering },
    } = store ?? { mobileOrdering: {} };
    const isEligibleForOrdering = availability === 'READY';
    const isEligibleForGuestOrdering = isEligibleForOrdering && guestOrdering;

    if (
      !isStoreOpen ||
      !isEligibleForOrdering ||
      (!isSignedIn && !isEligibleForGuestOrdering)
    ) {
      const showAccountRequiredMessage =
        isStoreOpen && isEligibleForOrdering && !isEligibleForGuestOrdering;

      return openModal({
        component: OrderingUnavailableDialog,
        ariaLabelledBy: ORDERING_UNAVAILABLE_DIALOG_ID,
        componentProps: {
          selectedStore: location,
          isStoreOpen,
          showAccountRequiredMessage,
        },
      });
    }

    if (!inStandaloneStoreLocator) {
      closeModal();
      dispatch(confirmStore(location, router));
    } else {
      const orderHereMenuUrl = `/menu?storeNumber=${store.storeNumber}&distance=${location.distance}`;
      window.location.href = orderHereMenuUrl;
    }
  };

  if (!store) {
    return null;
  }

  return (
    <article
      aria-labelledby={labelId}
      className={paddingClasses}
      data-e2e="expanded-location-content"
    >
      <div className="px4 lg-px6 pb2 text-xxs">
        <StoreWarnings store={store} />
      </div>
      <Helmet title={store.name} />
      <AccessibleDescription id={labelId} storeName={store.name} />
      <section className="pb5 px4 lg-px6">
        <Header storeData={store} />
        <LocationInfo storeData={location} />
      </section>
      <section className="pt2">
        <StoreSchedule
          hoursStatusFormatted={store.hoursStatusFormatted}
          schedule={store.schedule}
        />
      </section>
      <section>
        <StorePickUpOptions pickUpOptions={store.pickUpOptions} />
      </section>
      <section>
        <StoreAmenities amenities={store.amenities} />
      </section>
      <FrapContainer inHeaderCrate>
        <ConfirmStoreFrap
          location={location}
          onClick={confirmStoreOnClickHandler}
        />
      </FrapContainer>
    </article>
  );
};

export default ExpandedLocationContent;
