import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import OverlayWithCloseMessage from 'shared/app/components/overlay-with-close-message';
import { sharedCallsToAction } from 'shared/app/messages/ctas';

import { expandedStoreStateSelector } from '../../state/selectors';
import ExpandedLocationContent from '../expanded-location-content';

export const EXPANDED_STORE_OVERLAY_ID = 'ExpandedStoreOverlay';

export const ExpandedLocationContainer = ({ className }) => {
  const { formatMessage } = useIntl();

  const { expanded, store } = useSelector(expandedStoreStateSelector) ?? {};

  if (!expanded || !store?.storeNumber) {
    return null;
  }

  return (
    <OverlayWithCloseMessage
      alignment="leftCrate"
      className={`absolute ${className || ''}`}
      closeProps={{
        ariaLabel: formatMessage(sharedCallsToAction.closeLabel),
        'data-e2e': 'overlay-close-button',
      }}
      containerProps={{ 'data-e2e': 'overlay-container' }}
      showMask={false}
    >
      <ExpandedLocationContent />
    </OverlayWithCloseMessage>
  );
};

export default ExpandedLocationContainer;
