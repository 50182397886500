import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';

import IconButton from '@starbucks-web/pattern-library/lib/components/icon-button';
import infoPath from '@starbucks-web/pattern-library/lib/icons/info';
import heartPath from '@starbucks-web/pattern-library/lib/icons/heart';
import heartSolidPath from '@starbucks-web/pattern-library/lib/icons/heart-solid';
import { signedInSelector } from 'shared/app/bundles/user';

import messages from './messages';
import { trackStoreFinderDetailsView } from '../../state/actions/track-event';
import SignInRequiredDialog, {
  SIGN_IN_REQUIRED_DIALOG_ID,
} from '../sign-in-required-dialog';

import ExpandedLocationContainer, {
  EXPANDED_STORE_OVERLAY_ID,
} from '../expanded-location-container';
import {
  makeLocationFavorite,
  selectLocation,
} from '../../state/actions/location';

const StoreActionIcons = ({ location }) => {
  const { formatMessage } = useIntl();
  const { store } = location;
  const { openModal } = useModalContext();
  const dispatch = useDispatch();
  const isUserSignedIn = useSelector(signedInSelector);
  const isFavorite = location.isFavorite;

  const label = formatMessage(
    isFavorite ? messages.removeFavoriteLabel : messages.addFavoriteLabel,
    { storeName: store.name }
  );

  const onFavorite = () => {
    if (!isUserSignedIn) {
      openModal({
        component: SignInRequiredDialog,
        ariaLabelledBy: SIGN_IN_REQUIRED_DIALOG_ID,
      });
      return;
    }

    if (location.favoritePending) {
      return;
    }

    dispatch(makeLocationFavorite(location));
  };

  const onStoreExpand = () => {
    dispatch(
      selectLocation(store.storeNumber, {
        location,
        expanded: true,
      })
    );
    openModal({
      component: ExpandedLocationContainer,
      ariaLabelledBy: EXPANDED_STORE_OVERLAY_ID,
    });

    /* eslint-disable camelcase */
    trackStoreFinderDetailsView({
      content_state:
        store.mobileOrdering?.guestOrdering === false
          ? 'gco not available'
          : '',
      store_id: store.id,
    });
    /* eslint-enable camelcase */
  };

  return (
    <div className="flex items-start">
      <IconButton
        aria-label={label}
        className={isFavorite ? 'color-greenAccent' : 'color-textBlackSoft'}
        data-e2e="favorite"
        onClick={onFavorite}
        path={isFavorite ? heartSolidPath : heartPath}
        size="24px"
      />

      <IconButton
        aria-label={formatMessage(messages.linkLabel, {
          storeName: store.name,
        })}
        className="relative color-textBlackSoft ml1"
        data-e2e="cardLink"
        onClick={onStoreExpand}
        path={infoPath}
        size="24px"
      />
    </div>
  );
};

export default StoreActionIcons;
