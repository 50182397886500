import React from 'react';
import { useSelector } from 'react-redux';

import StoreWarnings from './store-warnings';
import StoreDetails from './store-details';
import StorePickUpOptionIconList from './store-pick-up-option-icon-list';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import ConfirmationButton from './confirmation-button';

import {
  nearestLocationLoadingSelector,
  selectedStoreNumberSelector,
} from '../../state/selectors';
import StoreActionIcons from './store-action-icons';

import styles from './styles.cssm';

export const LocationCardDetails = ({ location }) => {
  const nearestLocationLoading = useSelector(nearestLocationLoadingSelector);
  const selectedStoreNumber = useSelector(selectedStoreNumberSelector);

  const { store } = location;

  return (
    <div
      className={`flex justify-between ${
        nearestLocationLoading ? styles.contentIsLoading : styles.content
      }`}
    >
      <div
        className="flex flex-column justify-between items-start"
        data-e2e="location-card-details"
      >
        <div>
          <Heading className="text-bold" size="xxs" tagName="h3">
            {store.name}
          </Heading>
          <StoreDetails location={location} />
          <StoreWarnings store={store} />
        </div>
        <StorePickUpOptionIconList pickUpOptions={store.pickUpOptions} />
      </div>
      <div className="flex flex-column justify-between items-end">
        <StoreActionIcons location={location} />
        {selectedStoreNumber === store.storeNumber ? (
          <div className="my2">
            <ConfirmationButton />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LocationCardDetails;
